import { KeyboardDoubleArrowDown, KeyboardDoubleArrowRight } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import RotateLeftRoundedIcon from '@mui/icons-material/RotateLeftRounded';
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Dialog,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  useTheme
} from '@mui/material';
import { FC, memo, Reducer, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import ReactGA from 'react-ga4';
import Scrollbar from 'src/components/Scrollbar';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import LicenseContext from 'src/contexts/LicenseContext';
import { AllocateHistoryEntity } from 'src/entities/AllocateHistory.entity';
import { GarageEntity } from 'src/entities/Garage.entity';
import { PastOperationAndDriverIdEntity } from 'src/entities/PastOperationAndDriverId.entity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsGroupEntity } from 'src/entities/PlanningsGroup.entity';
import { PlanningsOperationEntity } from 'src/entities/PlanningsOperation.entity';
import {
  PlanningsOperationDeliveryByDeliveryIdEntity,
  PlanningsOperationEntitiesWithStatsByDeliveryIdEntity
} from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningStatisticsEntity } from 'src/entities/PlanningStatistics.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { PlanningsWorkingStatisticEntity } from 'src/entities/PlanningsWorkingStatistic.entity';
import { SelectedCycle } from 'src/entities/SelectedCycle.entity';
import { TransferRequestEntity } from 'src/entities/transferRequestEntity';
import { Co2CalculatorModel } from 'src/models/Co2Calculator.model';
import { PlanningsOperationPlace } from 'src/models/PlanningsOperationGroup.model';
import FormDialog from 'src/pages/V2DriversPresenter/FormDialog';
import ShiftsTablePresenter from 'src/presenters/ShiftsTablePresenter';
import arrayUtil from 'src/utils/array.util';
import numberUtil from 'src/utils/number.util';
import { SelectedStatusVo } from 'src/vo/SelectedStatus.vo';
import { TruckDisplayStatusVo } from 'src/vo/TruckDisplayStatusVo';

import { appBarHeight } from '../../../constants/layout';

import PlanningStatisticsPresenter from './PlanningStatistics.presenter';
import PlanningTruckPresenter from './PlanningTruck.presenter';
import TruckSelectMenuPresenter from './TruckSelectMenuPresenter';

type WorkingStatisticsesActions = {
  type: 'update';
  payload: PlanningsWorkingStatisticEntity
}

export const workingStatisticsesReducer: Reducer<PlanningsWorkingStatisticEntity[], WorkingStatisticsesActions> = (state, action) => {
  switch (action.type) {
    case 'update': {
      const updateDeliveryId = action.payload.deliveryId;

      return [
        ...state.filter((it) => updateDeliveryId !== it.deliveryId),
        action.payload
      ];
    }
    default:
      return state;
  }
};

type Props = {
  startOn: string;
  endOn: string;
  updateDisplayOrderId: (orderId: number) => void;
  mutateDeleteOrdersOperations: (requestOrderIds: number[]) => void;
  openTransferDialog: (entities: TransferRequestEntity) => void;
  mutateCloneLastWeekShifts: (date: string) => void;
  mutateDeleteShiftsOperations: (deliveryIds: number[]) => void;
  isLoading: boolean;
  garageData: GarageEntity[];
  truckEntities: PlanningsTruckEntity[];
  driverEntities: PlanningsDriverEntity[];
  deliveryEntities: PlanningsDeliveryEntity[];
  operationEntities: PlanningsOperationEntity[];
  swapRequest: (swapFromDeliveryId: number, swapToDeliveryId: number) => void;
  groupEntities: PlanningsGroupEntity[];
  selectedGroupEntities: PlanningsGroupEntity[] | undefined;
  updateSelectedGroupEntities: (entities: PlanningsGroupEntity[] | undefined) => void;
  planningsOperationEntitiesWithStatsByDeliveryIdEntity: PlanningsOperationEntitiesWithStatsByDeliveryIdEntity;
  sendOperationMail: () => void;
  refreshOperationStatuses: () => void;
  lastOperationStatusUpdated: string;
  resetDrivers: () => void;
  currentHistoryVersion: string | undefined;
  pastOperationsData: undefined | PastOperationAndDriverIdEntity[];
  latestAllocateHistory: AllocateHistoryEntity | undefined;
  selectPrintButtonOnClick: () => void;
  requestSingleAlgorithmPlanning: (deliveryId: number, orderOperationIdsForSort: number[], deleteOrderIdsFromOperations: number[], orderOperationCycleIndexes?: { [key: number]: number }) => void;
  selectedCycleIndexes: SelectedCycle[];
  updateSelectedCycleIndexes: (deliveryId: number, cycleIndexes: number[]) => void;
  planningsOperationDeliveryByDeliveryIdEntity: PlanningsOperationDeliveryByDeliveryIdEntity;
  resetEditPlaces: () => void;
  updateEditPlaces: (deliveryId: number, cycleIndex: number, places: PlanningsOperationPlace[]) => void;
  addEmptyCycle: (deliveryId: number) => void;
  removeEmptyCycle: (deliveryId: number, cycleIndex: number) => void;
  addDeliveryElementRef: (deliveryId: number, element: HTMLElement) => void;
  truckDisplayStatus: TruckDisplayStatusVo;
  setTruckDisplayStatus: (stauts: TruckDisplayStatusVo) => void;
  setAllDeliverySelected: (selected: boolean) => void;
  mutateRestoreSplittedOrder: (orderId: number) => void;
}

const PlanningTrucksPresenter: FC<Props> = memo(
  (
    {
      startOn,
      endOn,
      updateDisplayOrderId,
      mutateDeleteOrdersOperations,
      openTransferDialog,
      mutateCloneLastWeekShifts,
      mutateDeleteShiftsOperations,
      isLoading,
      garageData,
      truckEntities,
      driverEntities,
      deliveryEntities,
      operationEntities,
      swapRequest,
      groupEntities,
      selectedGroupEntities,
      updateSelectedGroupEntities,
      planningsOperationEntitiesWithStatsByDeliveryIdEntity,
      sendOperationMail,
      refreshOperationStatuses,
      lastOperationStatusUpdated,
      resetDrivers,
      currentHistoryVersion,
      pastOperationsData,
      latestAllocateHistory,
      selectPrintButtonOnClick,
      requestSingleAlgorithmPlanning,
      selectedCycleIndexes,
      updateSelectedCycleIndexes,
      planningsOperationDeliveryByDeliveryIdEntity,
      resetEditPlaces,
      updateEditPlaces,
      addEmptyCycle,
      removeEmptyCycle,
      addDeliveryElementRef,
      truckDisplayStatus,
      setTruckDisplayStatus,
      setAllDeliverySelected,
      mutateRestoreSplittedOrder,
    }
  ) => {
    const context = useContext(LicenseContext);
    const theme = useTheme();

    const falseInitialState = false;
    const selectedStatusInitialState = 'none';

    const [shiftDialogIsOpen, setShiftDialogIsOpen] = useState<boolean>(falseInitialState);
    const [selectedStatus, setSelectedStatus] = useState<SelectedStatusVo>(selectedStatusInitialState);
    const [planningStatisticsEntity, setPlanningStatisticsEntity] = useState<PlanningStatisticsEntity | undefined>(undefined);
    const [workingStatisticses, dispatchWorkingStatisticses] = useReducer(workingStatisticsesReducer, []);
    const [driverApp, setDriverApp] = useState<boolean>(false);
    const [co2Emissions, setCo2Emissions] = useState<number>(0);
    const [expandAll, setExpandAll] = useState(false);
    const selectedDeliveryIds = useMemo(() => selectedCycleIndexes.map((it) => it.deliveryId), [selectedCycleIndexes]);
    const [sortedTrucks, setSortedTrucks] = useState(truckEntities);

    const hasOperationTruckIds = useMemo(() => deliveryEntities.filter(({ operations: { length } }) => length).map(({ truckId }) => truckId), [deliveryEntities]);

    useEffect(() => {
      setAllDeliverySelected(selectedStatus === 'every');
    }, [selectedStatus, setAllDeliverySelected]);

    const updateWorkingStatisticses = useCallback((entity: PlanningsWorkingStatisticEntity) => {
      dispatchWorkingStatisticses({
        type: 'update',
        payload: entity
      });
    }, []);

    const shiftDialogClose = () => {
      setShiftDialogIsOpen(false);
    };
    const cloneButtonOnClick = () => {
      mutateCloneLastWeekShifts(startOn);
    };
    const resetButtonOnChange = useCallback(() => {
      ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: 'すべて未割り当て' });
      mutateDeleteShiftsOperations(selectedDeliveryIds);
    }, [mutateDeleteShiftsOperations, selectedDeliveryIds]);

    const swapButtonOnClick = useCallback(() => {
      ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '配車計画入れ替え' });
      if (selectedDeliveryIds.length !== 2) return;

      swapRequest(
        selectedDeliveryIds[0],
        selectedDeliveryIds[1],
      );
    }, [selectedDeliveryIds, swapRequest]);

    const canDisplay = useCallback((delivery: PlanningsDeliveryEntity) => {
      switch (truckDisplayStatus) {
        case 'すべて表示':
          return true;
        case '配車済み車両のみ表示':
          return hasOperationTruckIds.includes(delivery.truckId);
        case '未配車の車両のみ表示':
          return !hasOperationTruckIds.includes(delivery.truckId);
        case '選択中のみ表示する':
          return selectedDeliveryIds.includes(delivery.id);
        default:
          return true;
      }
    }, [hasOperationTruckIds, selectedDeliveryIds, truckDisplayStatus]);

    const [displayTruckIdsByGroup, setDisplayTruckIdsByGroup] = useState<number[]>([]);
    useEffect(() => {
      if (!(selectedGroupEntities && groupEntities && truckEntities)) return;

      if (selectedGroupEntities) {
        const ids = Array.from(new Set(selectedGroupEntities.filter((it) => it).flatMap((it) => it.truckIds)));
        setDisplayTruckIdsByGroup(ids);
      } else {
        setDisplayTruckIdsByGroup([]);
      }
    }, [groupEntities, selectedGroupEntities, truckEntities]);

    const checkboxOnChange = useCallback(() => {
      const deliveriesOnGroup = deliveryEntities
        .filter((it) => displayTruckIdsByGroup.includes(it.truckId));
      const deliveryIds = deliveriesOnGroup.map((it) => it.id);
      const deliveryIdsOnlyDisplay = deliveriesOnGroup
        .filter((it) => canDisplay(it))
        .map((it) => it.id);

      ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: `車両すべて選択 ${selectedStatus === 'every' ? 'OFF' : 'ON'}` });
      if (selectedStatus === 'every') {
        deliveryIds.forEach((it) => {
          updateSelectedCycleIndexes(it, []);
        });
        return;
      }

      deliveryIdsOnlyDisplay.forEach((it) => {
        const delivery = planningsOperationDeliveryByDeliveryIdEntity[it];
        if (delivery) {
          updateSelectedCycleIndexes(it, delivery.cycles.map((cycle) => cycle.cycleIndex));
        }
      });
    }, [deliveryEntities, selectedStatus, displayTruckIdsByGroup, canDisplay, updateSelectedCycleIndexes, planningsOperationDeliveryByDeliveryIdEntity]);

    const [editDriverId, setEditDriverId] = useState<number>();
    const driverDialogIsOpen = Boolean(editDriverId);

    const closeDriverEditDialog = useCallback(() => {
      setEditDriverId(null);
    }, []);

    const setDriverEditDialogIsOpen = useCallback((open: boolean) => {
      if (open) {
        setEditDriverId(editDriverId);
      } else {
        setEditDriverId(null);
      }
    }, [editDriverId]);

    const updateEditDriverId = useCallback((driverId: number) => {
      setEditDriverId(driverId);
    }, []);

    const driverEditDialogMemo = useMemo(() => {
      const maybe: PlanningsDriverEntity | undefined = driverEntities.find((it) => it.id === editDriverId);

      if (!maybe) return null;

      return (
        <FormDialog
          dialogIsOpen={driverDialogIsOpen}
          toggleDialogIsOpen={closeDriverEditDialog}
          setDialogIsOpen={setDriverEditDialogIsOpen}
          resetDrivers={resetDrivers}
          driverId={editDriverId}
        />
      );
    }, [driverEntities, driverDialogIsOpen, closeDriverEditDialog, setDriverEditDialogIsOpen, resetDrivers, editDriverId]);

    useMemo(() => {
      if (!context) { return; }
      if (!context.config) { return; }
      setDriverApp(context.config.use_driver_app);
    }, [context]);

    const swapButtonIsDisabled: boolean = useMemo(() => {
      const selectDeliveries = selectedDeliveryIds.map((it) => deliveryEntities.find((ent) => ent.id === it)).filter((maybe) => maybe);

      return [
        ...selectDeliveries.flatMap((it) => it.operations),
      ].length === 0;
    }, [selectedDeliveryIds, deliveryEntities]);

    const deliveriesOnTheTruckMap = useMemo(() => {
      const map = new Map<number, PlanningsDeliveryEntity[]>();
      deliveryEntities.forEach((it) => {
        const tmp = map.get(it.truckId) || [];
        tmp.push(it);
        map.set(it.truckId, tmp);
      });
      return map;
    }, [deliveryEntities]);

    useEffect(() => {
      if (deliveryEntities.length === 0 || driverEntities.length === 0 || truckEntities.length === 0) return;

      const MAX_SORT_ORDER = 999999;
      const sorted = truckEntities.sort((a, b) => {
        let ret = 0;
        const aDelivery = deliveryEntities.find((it) => it.truckId === a.id);
        const bDelivery = deliveryEntities.find((it) => it.truckId === b.id);
        ret = (a.sortOrder ?? MAX_SORT_ORDER) - (b.sortOrder ?? MAX_SORT_ORDER);
        if (ret !== 0) {
          return ret;
        }
        const aDriver = driverEntities.find((it) => it.id === aDelivery.driverId);
        const bDriver = driverEntities.find((it) => it.id === bDelivery.driverId);
        ret = (aDriver.sortOrder ?? MAX_SORT_ORDER) - (bDriver.sortOrder ?? MAX_SORT_ORDER);
        return ret;
      });
      setSortedTrucks(sorted);
    }, [deliveryEntities, driverEntities, truckEntities]);

    const trucksMemo = useMemo(() => {
      if (!garageData) return '';

      return (
        <Stack
          pt={0.5}
          pb={36}
        >
          {sortedTrucks
            .filter((it) => displayTruckIdsByGroup.includes(it.id))
            .map((truck) => {
              // const deliveriesOnTheTruck = deliveryEntities.filter((it) => it.truckId === truck.id);
              const deliveriesOnTheTruck = deliveriesOnTheTruckMap.get(truck.id);
              if (!deliveriesOnTheTruck) return null;

              let display = '';
              if (
                deliveriesOnTheTruck.every((it) => !canDisplay(it))
              ) {
                display = 'none';
              }
              const garage = garageData.find((it) => it.id === truck.garageId);

              return (
                <Stack sx={{ display }} key={['PlanningTrucksPresenter', 'truckMemo', truck.licensePlateValue].join('-')}>
                  <PlanningTruckPresenter
                    key={[
                      'PlanningTrucksPresenter',
                      'PlanningTruckPresenter',
                      truck.id
                    ].join('-')}
                    entity={truck}
                    garage={garage}
                    isLoading={isLoading}
                    driverEntities={driverEntities}
                    deliveryEntities={deliveryEntities}
                    operationEntities={operationEntities}
                    updateDisplayOrderId={updateDisplayOrderId}
                    mutateDeleteOrdersOperations={mutateDeleteOrdersOperations}
                    openTransferDialog={openTransferDialog}
                    truckEntities={truckEntities}
                    updateWorkingStatisticses={updateWorkingStatisticses}
                    currentHistoryVersion={currentHistoryVersion}
                    updateEditDriverId={updateEditDriverId}
                    deliveriesOnTheTruck={deliveriesOnTheTruck}
                    pastOperationsData={pastOperationsData}
                    latestAllocateHistory={latestAllocateHistory}
                    expandAll={expandAll}
                    requestSingleAlgorithmPlanning={requestSingleAlgorithmPlanning}
                    startOn={startOn}
                    endOn={endOn}
                    selectedCycleIndexes={selectedCycleIndexes}
                    updateSelectedCycleIndexes={updateSelectedCycleIndexes}
                    planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
                    resetEditPlaces={resetEditPlaces}
                    updateEditPlaces={updateEditPlaces}
                    addEmptyCycle={addEmptyCycle}
                    removeEmptyCycle={removeEmptyCycle}
                    addDeliveryElementRef={addDeliveryElementRef}
                    mutateRestoreSplittedOrder={mutateRestoreSplittedOrder}
                  />
                </Stack>
              );
            })}
        </Stack>
      );
    }, [garageData, truckEntities, displayTruckIdsByGroup, deliveriesOnTheTruckMap, isLoading, driverEntities, deliveryEntities, operationEntities, updateDisplayOrderId, mutateDeleteOrdersOperations, openTransferDialog, updateWorkingStatisticses, currentHistoryVersion, updateEditDriverId, pastOperationsData, latestAllocateHistory, expandAll, requestSingleAlgorithmPlanning, startOn, endOn, selectedCycleIndexes, updateSelectedCycleIndexes, planningsOperationDeliveryByDeliveryIdEntity, resetEditPlaces, updateEditPlaces, canDisplay, addEmptyCycle, removeEmptyCycle, addDeliveryElementRef, mutateRestoreSplittedOrder]);

    const planningStatisticsPresenterMemo = useMemo(() => (
      <Stack
        py={0.5}
        pl={1.5}
      >
        <PlanningStatisticsPresenter
          planningStatistics={planningStatisticsEntity}
          refreshOperationStatuses={refreshOperationStatuses}
          lastOperationStatusUpdated={lastOperationStatusUpdated}
          isLoading={isLoading}
          currentHistoryVersion={currentHistoryVersion}
          driverApp={driverApp}
          co2Emissions={co2Emissions}
          selectPrintButtonOnClick={selectPrintButtonOnClick}
          sendOperationMail={sendOperationMail}
          selectedDeliveryIds={selectedDeliveryIds}
        />
      </Stack>
    ), [planningStatisticsEntity, refreshOperationStatuses, lastOperationStatusUpdated, isLoading, currentHistoryVersion, driverApp, co2Emissions, selectPrintButtonOnClick, sendOperationMail, selectedDeliveryIds]);

    useEffect(() => {
      if (!truckEntities) {
        setCo2Emissions(0);
        return;
      }
      if (!deliveryEntities) {
        setCo2Emissions(0);
        return;
      }

      const arrayOfCo2Emissions = deliveryEntities.filter((delivery) => delivery.operations.length).map((delivery) => {
        const truck = truckEntities.find((it) => it.id === delivery.truckId);

        if (!truck) {
          return 0;
        }

        const distanceMms = [
          ...delivery.operations.map((it) => it.drivingDistanceMm),
          delivery.arrivalDistanceMm
        ].filter((maybe) => maybe);

        const distanceKms = distanceMms.map((it) => numberUtil.convertFromMmToKm(it));

        const distanceKm = distanceKms.reduce((a, b) => a + b, 0);

        const loadingCapacityGram = truck.maximumLoadingCapacityWeightForCalculation;

        const loadingCapacityKg = numberUtil.convertFromGramToKg(loadingCapacityGram);

        const model = new Co2CalculatorModel(distanceKm, loadingCapacityKg);

        return model.performCalculation();
      });

      setCo2Emissions(
        arrayOfCo2Emissions.reduce((a, b) => a + b, 0)
      );
    }, [truckEntities, deliveryEntities]);

    useEffect(() => {
      if (!operationEntities.length) {
        setTruckDisplayStatus('すべて表示');
      }
    }, [operationEntities, setTruckDisplayStatus]);

    useEffect(() => {
      const deliveryIds = deliveryEntities
        .filter((it) => displayTruckIdsByGroup.includes(it.truckId))
        .filter((it) => canDisplay(it))
        .map((it) => it.id);

      if (!selectedDeliveryIds.length) {
        setSelectedStatus(
          'none'
        );

        return;
      }
      if (deliveryIds.every((it) => selectedDeliveryIds.includes(it))) {
        setSelectedStatus(
          'every'
        );

        return;
      }
      if (deliveryEntities.some((it) => selectedDeliveryIds.includes(it.id))) {
        setSelectedStatus(
          'some'
        );
      }
    }, [selectedDeliveryIds, deliveryEntities, truckDisplayStatus, canDisplay, displayTruckIdsByGroup]);

    useEffect(() => {
      if (![deliveryEntities, truckEntities, operationEntities, planningsOperationEntitiesWithStatsByDeliveryIdEntity, workingStatisticses].every((maybe) => maybe)) return;

      const groupedEntities = planningsOperationEntitiesWithStatsByDeliveryIdEntity;
      const totalMaxWeightG = Object
        .values(groupedEntities)
        .map((entities: (PlanningsOperationEntity & { currentVolumeMm3: number; currentWeightG: number })[]) => entities.map((entity) => entity.currentWeightG))
        .filter((maybeEmpty) => !!maybeEmpty.length)
        .map((maxes) => Math.max(...maxes))
        .reduce((prev, current) => prev + current, 0);
      const totalMaxVolumeMm3 = Object
        .values(groupedEntities)
        .map((entities: (PlanningsOperationEntity & { currentVolumeMm3: number; currentWeightG: number })[]) => entities.map((entity) => entity.currentVolumeMm3))
        .filter((maybeEmpty) => !!maybeEmpty.length)
        .map((maxes) => Math.max(...maxes))
        .reduce((prev, current) => prev + current, 0);
      const haveOperationDeliveryEntities: PlanningsDeliveryEntity[] = arrayUtil
        .uniq<number>(operationEntities.map((it) => it.shiftTruckId))
        .map((it) => deliveryEntities.find((ent) => ent.id === it))
        .filter((maybe) => maybe);
      const haveOperationTruckEntities = truckEntities
        .filter(({ id }) => haveOperationDeliveryEntities.map(({ truckId }) => truckId).includes(id));
      const haveOperationTruckTotalCapacityWeightG = haveOperationTruckEntities
        .map(({ maximumLoadingCapacityWeightForCalculation }) => maximumLoadingCapacityWeightForCalculation)
        .filter((maybe) => maybe)
        .reduce((prev, current) => prev + current, 0);
      const haveOperationTruckTotalCapacityVolumeMm3 = haveOperationTruckEntities
        .map(({ loadingPlatformVolumeMm3 }) => loadingPlatformVolumeMm3)
        .filter((maybe) => maybe)
        .reduce((prev, current) => prev + current, 0);
      const existOrderOperations = Object
        .values(operationEntities)
        .flat();
      const haveOperationDeliveryTotalDistanceMm = haveOperationDeliveryEntities.length
        ? existOrderOperations
          .map((it) => it.drivingDistanceMm)
          .reduce((prev, current) => prev + current, 0)
        + haveOperationDeliveryEntities
          .map((it) => it?.arrivalDistanceMm)
          .filter((maybe) => maybe)
          .reduce((prev, current) => prev + current, 0)
        : 0;
      const loadingOperations = operationEntities.filter((it) => it.action === '積');

      const totalPlanMinutes = workingStatisticses
        .filter((it) => it.operationCount)
        .map((it) => it.plannedMinutes)
        .reduce((prev, current) => prev + current, 0);
      const totalWorkMinutes = workingStatisticses
        .filter((it) => it.operationCount)
        .map((it) => it.workMinutes)
        .reduce((prev, current) => prev + current, 0);

      const totalOrdersCount = loadingOperations.length;
      const totalLoadWeightG = loadingOperations
        .map((it) => it.itemTotalWeightForCalculation)
        .filter((maybe) => maybe)
        .reduce((prev, current) => prev + current, 0);
      const totalLoadVolumeMm3 = loadingOperations
        .map((it) => it.itemTotalVolumeMm3)
        .filter((maybe) => maybe)
        .reduce((prev, current) => prev + current, 0);
      const loadCapacityWeightG = haveOperationTruckTotalCapacityWeightG;
      const loadCapacityVolumeMm3 = haveOperationTruckTotalCapacityVolumeMm3;
      const totalMaxLoadWeightG = totalMaxWeightG;
      const totalMaxLoadVolumeMm3 = totalMaxVolumeMm3;
      const totalDistanceMm = haveOperationDeliveryTotalDistanceMm;

      setPlanningStatisticsEntity((prev) => {
        if (prev
            && prev.totalOrdersCount === totalOrdersCount
            && prev.totalLoadWeightG === totalLoadWeightG
            && prev.totalLoadVolumeMm3 === totalLoadVolumeMm3
            && prev.totalMaxLoadWeightG === totalMaxLoadWeightG
            && prev.totalMaxLoadVolumeMm3 === totalMaxLoadVolumeMm3
            && prev.loadCapacityWeightG === loadCapacityWeightG
            && prev.loadCapacityVolumeMm3 === loadCapacityVolumeMm3
            && prev.totalPlanMinutes === totalPlanMinutes
            && prev.totalWorkMinutes === totalWorkMinutes
            && prev.totalDistanceMm === totalDistanceMm
        ) {
            return prev;
        }
        return {
          totalOrdersCount,
          totalLoadWeightG,
          totalLoadVolumeMm3,
          totalMaxLoadWeightG,
          totalMaxLoadVolumeMm3,
          loadCapacityWeightG,
          loadCapacityVolumeMm3,
          totalPlanMinutes,
          totalWorkMinutes,
          totalDistanceMm
        };
      });
    }, [deliveryEntities, truckEntities, operationEntities, planningsOperationEntitiesWithStatsByDeliveryIdEntity, workingStatisticses]);

    const [resetTooltip, setResetTooltip] = useState<string>('');
    const [swapTooltip, setSwapTooltip] = useState<string>('');
    useEffect(() => {
      if (context.config?.selected_company_id === 0) {
        setResetTooltip('事業所を選択してください');
        setSwapTooltip('事業所を選択してください');
      } else {
        setResetTooltip('選択した勤務計画の配送計画をすべて未割り当てにする');
        setSwapTooltip('選択した２つの勤務計画の配送計画を入れ替える');
      }
    }, [context.config?.selected_company_id]);

    const selectedDeliveryIdsMemo = useMemo(() => (
      <Badge
        color="primary"
        badgeContent={selectedDeliveryIds.length}
        max={10000}
      >
        <Tooltip
          title={
            selectedStatus === 'every' ? 'すべて選択解除する' : 'すべて選択する'
          }
          arrow
        >
          <Checkbox
            size="small"
            onChange={checkboxOnChange}
            checked={selectedStatus === 'every'}
            indeterminate={selectedStatus === 'some'}
            disabled={isLoading}
            sx={{
              m: 0,
              p: 0.3,
            }}
          />
        </Tooltip>
      </Badge>
    ), [checkboxOnChange, isLoading, selectedDeliveryIds.length, selectedStatus]);

    const resetButtonMemo = useMemo(() => (
      <Tooltip
        title={resetTooltip}
        arrow
      >
        <span>
          <IconButton
            size="small"
            disabled={isLoading || !!currentHistoryVersion || (context.config?.selected_company_id === 0)}
            onClick={resetButtonOnChange}
          >
            <RotateLeftRoundedIcon />
          </IconButton>
        </span>
      </Tooltip>
    ), [context.config?.selected_company_id, currentHistoryVersion, isLoading, resetButtonOnChange, resetTooltip]);

    const swapButtonMemo = useMemo(() => (
      <Tooltip
        title={swapTooltip}
        arrow
      >
        <span>
          <IconButton
            size="small"
            disabled={swapButtonIsDisabled || !(selectedDeliveryIds.length === 2) || isLoading || !!currentHistoryVersion || (context.config?.selected_company_id === 0)}
            onClick={swapButtonOnClick}
          >
            <SwapHorizRoundedIcon />
          </IconButton>
        </span>
      </Tooltip>
    ), [context.config?.selected_company_id, currentHistoryVersion, isLoading, selectedDeliveryIds.length, swapButtonIsDisabled, swapButtonOnClick, swapTooltip]);

    const onChangeExpandAll = (value: boolean) => {
      ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: `車両リスト ${value ? '開く' : '閉じる'}` });
      setExpandAll(value);
    };

    return (
      <Stack>
        <Stack
          sx={{
            position: 'sticky',
            // display: 'block',
            top: 0,
            zIndex: 1,
          }}
        >
          <Paper
            square
          >
            {planningStatisticsPresenterMemo}
          </Paper>
          <Paper
            square
            sx={{
              height: appBarHeight,
              borderBottom: 1,
              borderBottomColor: theme.colors.secondary.light,
            }}
          >
            <Stack
              p={1}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                {selectedDeliveryIdsMemo}
                {(selectedDeliveryIds.length > 0) && (
                  <>
                    {resetButtonMemo}
                    {swapButtonMemo}
                  </>
                )}
                <TruckSelectMenuPresenter
                  truckDisplayStatus={truckDisplayStatus}
                  setTruckDisplayStatus={setTruckDisplayStatus}
                  groupEntities={groupEntities}
                  updateSelectedGroupEntities={updateSelectedGroupEntities}
                />
                {deliveryEntities.length > 0 && (
                  <IconButton
                    size="small"
                    sx={{ width: '30px' }}
                    onClick={() => onChangeExpandAll(!expandAll)}
                  >
                    {expandAll ? <KeyboardDoubleArrowDown /> : <KeyboardDoubleArrowRight />}
                  </IconButton>
                )}
              </Stack>
            </Stack>
          </Paper>
        </Stack>
        <Scrollbar style={{ height: 'calc(100vh - 240px)' }}>
          {trucksMemo}
        </Scrollbar>
        <Dialog
          sx={{
            mt: 8
          }}
          open={shiftDialogIsOpen}
          onClose={shiftDialogClose}
          fullScreen
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 8
            }}
          >
            <Button
              fullWidth
              onClick={cloneButtonOnClick}
              sx={{
                fontSize: '16px'
              }}
            >
              先週のシフトをコピーする
            </Button>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 8
            }}
          >
            <IconButton
              onClick={shiftDialogClose}
              sx={{
                backgroundColor: '#fff'
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Box>
          <ShiftsTablePresenter startOn={startOn} />
        </Dialog>
        {driverEditDialogMemo}
      </Stack>
    );
  }
);

export default PlanningTrucksPresenter;

import { closestCenter, DndContext, DragEndEvent, DragOverEvent, DragOverlay, DragStartEvent, KeyboardSensor, PointerSensor, UniqueIdentifier, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { verticalListSortingStrategy, SortableContext, sortableKeyboardCoordinates, useSortable, arrayMove } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ForwardIcon from '@mui/icons-material/Forward';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import RotateLeftRoundedIcon from '@mui/icons-material/RotateLeftRounded';
import SaveIcon from '@mui/icons-material/Save';
import { Checkbox, Collapse, IconButton, List, ListItem, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import numberDecorator from 'src/decorators/number.decorator';
import { PastOperationAndDriverIdEntity } from 'src/entities/PastOperationAndDriverId.entity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsOperationDeliveryByDeliveryIdEntity } from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningsOperationEntityWithStatsEntity } from 'src/entities/PlanningsOperationEntityWithStats.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { TransferRequestEntity } from 'src/entities/transferRequestEntity';
import { PlanningsOperationCycle, PlanningsOperationPlace } from 'src/models/PlanningsOperationGroup.model';

import PlanningsPlacePresenter from './PlanningsPlace.presenter';

type Props = {
  deliveryId: number;
  driverId: number;
  truck: PlanningsTruckEntity;
  cycle: PlanningsOperationCycle;
  pastOperations: PastOperationAndDriverIdEntity[];
  requestSingleAlgorithmPlanning: (deliveryId: number, orderOperationIdsForSort: number[], deleteOrderIdsFromOperations: number[], orderOperationCycleIndexes?: { [key: number]: number }) => void;
  selectedCycleIndexes: number[];
  updateSelectedCycleIndexes: (idx: number) => void;
  mutateDeleteOrdersOperations: (requestOrderIds: number[]) => void;
  setTransferDialogIsOpen: (open: boolean) => void;
  canTransfer: boolean;
  addSelectedOperations: (operations: PlanningsOperationEntityWithStatsEntity[]) => void;
  removeSelectedOperations: (operations: PlanningsOperationEntityWithStatsEntity[]) => void;
  startOn: string;
  endOn: string;
  updateDisplayOrderId: (orderId: number) => void;
  openTransferDialog: (entity: TransferRequestEntity) => void;
  isLoading: boolean;
  deliveryEntities: PlanningsDeliveryEntity[];
  driverEntities: PlanningsDriverEntity[];
  truckEntities: PlanningsTruckEntity[];
  deliveryIsExpanded: boolean;
  updateEditPlaces: (deliveryId: number, cycleIndex: number, places: PlanningsOperationPlace[]) => void;
  planningsOperationDeliveryByDeliveryIdEntity: PlanningsOperationDeliveryByDeliveryIdEntity;
  setPlaceEdited: (edited: boolean) => void;
  updateCycleExpanded: (cycleId: string, expanded: boolean) => void;
  expanded?: boolean;
  onClickDeleteCycle: (cycleIndex: number) => void;
  addedOrderIds: number[];
  parentCheckedOperationAdded: boolean;
  mutateRestoreSplittedOrder: (orderId: number) => void;
}

const PlanningsCyclePresenter: FC<Props> = memo(({
  deliveryId,
  driverId,
  truck,
  cycle,
  pastOperations,
  requestSingleAlgorithmPlanning,
  selectedCycleIndexes,
  updateSelectedCycleIndexes,
  mutateDeleteOrdersOperations,
  setTransferDialogIsOpen,
  canTransfer,
  addSelectedOperations,
  removeSelectedOperations,
  startOn,
  endOn,
  updateDisplayOrderId,
  openTransferDialog,
  isLoading,
  deliveryEntities,
  driverEntities,
  truckEntities,
  deliveryIsExpanded,
  updateEditPlaces,
  planningsOperationDeliveryByDeliveryIdEntity,
  setPlaceEdited,
  updateCycleExpanded,
  expanded,
  onClickDeleteCycle,
  addedOrderIds,
  parentCheckedOperationAdded,
  mutateRestoreSplittedOrder,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedPlaces, setSelectedPlaces] = useState<string[]>([]);
  const [isExpanded, setIsExpanded] = useState(expanded ?? false);
  const [editPlaces, setEditPlaces] = useState<PlanningsOperationPlace[]>(cycle.places);
  const [edited, setEdited] = useState(false);
  const [activeId, setActiveId] = useState<UniqueIdentifier>(null);
  const [checkedOperationAdded, setCheckedOperationAdded] = useState<boolean>(false);

  useEffect(() => {
    if (!parentCheckedOperationAdded) {
      setCheckedOperationAdded(false);
      return;
    }
    const currentOrderIds = cycle.allOperations().map((it) => it.orderId);
    setCheckedOperationAdded(addedOrderIds.some((it) => currentOrderIds.includes(it)));
  }, [addedOrderIds, cycle, parentCheckedOperationAdded]);

  const bgColor = () => {
    if (checkedOperationAdded) return '#EAEFF6';
    return '';
  };

  const expandButtonOnClick = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const { delivery } = cycle;
  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: cycle.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
  };

  const cycleIndex = useMemo(() => cycle.cycleIndex, [cycle.cycleIndex]);
  const cycleText = useMemo(() => ['回転', cycleIndex].join(''), [cycleIndex]);
  const statsText = useMemo(() => {
    if (cycle.empty) {
      return '';
    }

    const count = ['数量:', cycle.itemCount].join('');
    const weight = [
      numberDecorator.convertGramToKg(cycle.maxWeightG, 1, false),
      numberDecorator.convertGramToKg(truck.maximumLoadingCapacityWeightForCalculation, 1),
    ].join('/');
    const volume = truck.loadingPlatformVolumeMm3 && cycle.maxVolumeMm3
      ? [
        numberDecorator.convertMm3ToM3(cycle.maxVolumeMm3, 1, false),
        numberDecorator.convertMm3ToM3(truck.loadingPlatformVolumeMm3, 1)
      ].join('/')
      : null;
    const weightAndVolume = ['積:', [weight, volume].filter((it) => it).join(', ')].join('');

    return ['(', [count, weightAndVolume].join(' '), ')'].join('');
  }, [cycle, truck]);

  const onChangePlaceSelection = useCallback((place: PlanningsOperationPlace) => {
    if (selectedPlaces.includes(place.id)) {
      removeSelectedOperations(place.allOperations());
    } else {
      addSelectedOperations(place.allOperations());
    }

    setSelectedPlaces((prev) => {
      if (prev.includes(place.id)) {
        return prev.filter((it) => it !== place.id);
      }
      return [...prev, place.id];
    });
  }, [addSelectedOperations, removeSelectedOperations, selectedPlaces]);

  const onClickSaveButton = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '配車順並べ替え 保存' });
    // eslint-disable-next-line no-alert
    if (window.confirm('並べ替えを確定します。よろしいですか？')) {
      const sortedOperationIds: number[] = [];
      delivery.cycles.forEach((cycl) => {
        if (cycl.id === cycle.id) {
          const operationIds = editPlaces.flatMap((place) => place.allOperations().map(({ id }) => id));
          sortedOperationIds.push(...operationIds);
        } else {
          sortedOperationIds.push(...cycl.allOperations().map(({ id }) => id));
        }
      });
      requestSingleAlgorithmPlanning(deliveryId, sortedOperationIds, []);
    }
  }, [cycle.id, delivery.cycles, deliveryId, editPlaces, requestSingleAlgorithmPlanning]);

  const onClickDeleteButton = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: 'チェックした配車計画を未割り当てに戻す' });
    if (selectedPlaces.length === 0) return;

    // eslint-disable-next-line no-alert
    if (window.confirm('選択した計画を未割り当てに戻します。よろしいですか？')) {
      const places = cycle.places.filter((it) => selectedPlaces.includes(it.id));
      const deleteOrderIds = Array.from(new Set(places.flatMap((it) => it.orderIds)));
      const sortedOPerationIds: number[] = [];
      delivery.cycles.forEach((cycl) => {
        sortedOPerationIds.push(...cycl.allOperations().filter((it) => !deleteOrderIds.includes(it.orderId)).map(({ id }) => id));
      });
      mutateDeleteOrdersOperations(deleteOrderIds);
    }
  }, [cycle.places, delivery.cycles, mutateDeleteOrdersOperations, selectedPlaces]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id);
  }, []);

  const handleDragOver = useCallback((event: DragOverEvent) => {
    const { active, over } = event;
    if (![active, over].every((it) => it)) return;
    if (active.id === over.id) return;

    const activeIndex = editPlaces.findIndex((it) => it.id === active.id);
    const overIndex = editPlaces.findIndex((it) => it.id === over.id);
    const sorted = arrayMove(editPlaces, activeIndex, overIndex);

    const loadIndex = {};
    const unloadIndex = {};
    sorted.forEach((it, i) => {
      it.allOperations().forEach((ops) => {
        if (ops.action === '積') {
          loadIndex[ops.orderId] = i;
        } else {
          unloadIndex[ops.orderId] = i;
        }
      });
    });

    let err = false;
    Object.keys(loadIndex).forEach((orderId) => {
      if (loadIndex[orderId] > unloadIndex[orderId]) {
        err = true;
      }
    });
    if (err) {
      enqueueSnackbar('積地を降地よりもあとに設定できません。');
      return;
    }
    const startIndex = Math.min(...sorted.map((it) => it.seriesIndex));
    sorted.forEach((it, i) => {
      it.seriesIndex = startIndex + i;
    });

    setEditPlaces(sorted);
  }, [editPlaces, enqueueSnackbar]);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    setActiveId(null);
  }, []);

  useEffect(() => {
    if (!editPlaces) return;
    if (activeId) return;

    const edit = cycle.places.reduce((ret, place, i) => {
      if (ret) return ret;
      return place.id !== editPlaces[i]?.id;
    }, false);
    setEdited(edit);

    updateEditPlaces(
      deliveryId,
      cycleIndex,
      editPlaces,
    );
  }, [activeId, cycle.places, cycleIndex, deliveryId, editPlaces, updateEditPlaces]);

  useEffect(() => {
    setPlaceEdited(edited);
  }, [edited, setPlaceEdited]);

  useEffect(() => {
    setEditPlaces(cycle.places);
  }, [cycle.places]);

  const onTransferButtonClick = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: 'チェックした配車計画を移動' });
    setTransferDialogIsOpen(true);
  }, [setTransferDialogIsOpen]);

  useEffect(() => {
    setSelectedPlaces((prev) => prev.filter((it) => cycle.places.map((plc) => plc.id).includes(it)));
  }, [cycle.places]);

  useEffect(() => {
    if (expanded === undefined && deliveryIsExpanded) {
      setIsExpanded(true);
    }
  }, [deliveryIsExpanded, expanded]);

  useEffect(() => {
    updateCycleExpanded(cycle.id, isExpanded);
  }, [cycle.id, isExpanded, updateCycleExpanded]);

  const checked = useMemo(() => selectedCycleIndexes.includes(cycleIndex), [cycleIndex, selectedCycleIndexes]);

  const onClickCycleCheckbox = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: `回転チェック ${checked ? 'OFF' : 'ON'}`, label: `回転${cycleIndex}` });
    updateSelectedCycleIndexes(cycleIndex);
  }, [checked, cycleIndex, updateSelectedCycleIndexes]);

  return (
    <Stack
      {...attributes}
      ref={setNodeRef}
      style={style}
      sx={{ backgroundColor: bgColor }}
    >
      <Stack
        direction="row"
        alignItems="center"
      >
        <IconButton
          onClick={expandButtonOnClick}
          size="small"
        >
          {isExpanded
            ? <KeyboardArrowUpRoundedIcon fontSize="small" />
            : <ExpandMoreRoundedIcon fontSize="small" />}
        </IconButton>
        <Checkbox
          size="small"
          sx={{
            m: 0,
            p: 0.3,
          }}
          onClick={onClickCycleCheckbox}
          checked={checked}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          sx={{
            '&:hover .deleteIcon': {
              display: 'flex',
            }
          }}
        >
          <Stack>
            <Tooltip title="ドラッグして回転を並べ替えます">
              <Stack
                direction="row"
                onClick={() => updateSelectedCycleIndexes(cycleIndex)}
                sx={{ cursor: 'grab' }}
                {...listeners}
                alignItems="center"
              >
                <Typography whiteSpace="nowrap">{cycleText}</Typography>
                <Typography sx={{ fontSize: '0.9em' }}>{statsText}</Typography>
              </Stack>
            </Tooltip>
          </Stack>
          <Stack sx={{ mr: 3 }}>
            {(cycle.empty && (
              <Tooltip title="この回転を削除します">
                <span>
                  <IconButton
                    size="small"
                    sx={{ display: 'none', m: 0, p: 0, }}
                    className="deleteIcon"
                    onClick={() => onClickDeleteCycle(cycle.cycleIndex)}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </span>
              </Tooltip>
            ))}
          </Stack>
        </Stack>
      </Stack>
      {
        !cycle.empty && (
          <Collapse in={isExpanded}>
            <List component="div" disablePadding>
              <ListItem>
                <Paper
                  elevation={3}
                  sx={{
                    padding: '8px',
                    marginBottom: '8px',
                    backgroundColor: '#f0f0f0',
                    borderRadius: '10px',
                    width: '100%',
                    position: 'relative',
                    marginLeft: '10px',
                    marginRight: '5px',
                  }}
                >
                  <Stack
                    direction="row"
                    sx={{
                      display: (edited || selectedPlaces.length > 0) ? '' : 'none',
                      m: 0,
                      p: 0,
                    }}
                    justifyContent="flex-end"
                  >
                    <Stack
                      direction="row"
                      sx={{
                        display: selectedPlaces.length > 0 ? '' : 'none',
                        m: 0,
                        p: 0,
                      }}
                    >
                      {(selectedPlaces.length > 0 && canTransfer) && (
                        <Tooltip title="選択した計画を移動します" arrow>
                          <span>
                            <IconButton
                              sx={{
                                my: 0,
                                py: 0,
                              }}
                              onClick={onTransferButtonClick}
                            >
                              <ForwardIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                      {selectedPlaces.length > 0 && (
                        <Tooltip title="選択した計画を未割り当てに戻します" arrow>
                          <span>
                            <IconButton
                              onClick={onClickDeleteButton}
                              sx={{
                                my: 0,
                                py: 0,
                              }}
                            >
                              <RotateLeftRoundedIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                    </Stack>
                    <Stack
                      direction="row"
                      sx={{
                        display: edited ? '' : 'none',
                        m: 0,
                        p: 0,
                      }}
                    >
                      {edited && (
                        <Tooltip title="並べ替えを確定します" arrow>
                          <span>
                            <IconButton
                              onClick={onClickSaveButton}
                              sx={{
                                my: 0,
                                py: 0,
                              }}
                            >
                              <SaveIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                    </Stack>
                  </Stack>
                  <Stack
                    sx={{
                      overflow: 'hidden',
                    }}
                  >
                    <DndContext
                      sensors={sensors}
                      collisionDetection={closestCenter}
                      onDragStart={handleDragStart}
                      onDragOver={handleDragOver}
                      onDragEnd={handleDragEnd}
                      onDragCancel={handleDragEnd}
                      modifiers={[restrictToVerticalAxis]}
                    >
                      <SortableContext
                        id={`SortableContext-${deliveryId}`}
                        items={editPlaces}
                        strategy={verticalListSortingStrategy}
                      >
                        {editPlaces.map((place, idx) => (
                          <PlanningsPlacePresenter
                            key={['PlanningsPlacePresenter', place.id].join('-')}
                            idx={idx}
                            cycle={cycle}
                            place={place}
                            pastOperations={pastOperations}
                            onChangePlaceSelection={onChangePlaceSelection}
                            driverEntitiesOnPosition={driverEntities.filter((it) => it.id === driverId)}
                            startOn={startOn}
                            endOn={endOn}
                            updateDisplayOrderId={updateDisplayOrderId}
                            mutateDeleteOrdersOperations={mutateDeleteOrdersOperations}
                            openTransferDialog={openTransferDialog}
                            isLoading={isLoading}
                            deliveryEntitiesOnPosition={deliveryEntities.filter((it) => it.id === deliveryId)}
                            truckEntities={truckEntities}
                            deliveryEntities={deliveryEntities}
                            driverEntities={driverEntities}
                            editPlaces={[{
                              deliveryId,
                              cycleIndex,
                              places: [place]
                            }]}
                            planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
                            addedOrderIds={addedOrderIds}
                            parentCheckedOperationAdded={checkedOperationAdded}
                            mutateRestoreSplittedOrder={mutateRestoreSplittedOrder}
                          />
                        ))}
                        {/* ドラッグ中の表示 */}
                        <DragOverlay>
                          {activeId && (
                            <div
                              style={{
                                padding: 0,
                                margin: 0,
                                backgroundColor: '#f0f0f0',
                              }}
                            >
                              <PlanningsPlacePresenter
                                key={['PlanningsPlacePresenter', editPlaces.find((it) => it.id === activeId)?.id].join('-')}
                                idx={editPlaces.findIndex((it) => it.id === activeId)}
                                cycle={cycle}
                                place={editPlaces.find((it) => it.id === activeId)}
                                pastOperations={pastOperations}
                                onChangePlaceSelection={onChangePlaceSelection}
                                driverEntitiesOnPosition={driverEntities.filter((it) => it.id === driverId)}
                                startOn={startOn}
                                endOn={endOn}
                                updateDisplayOrderId={updateDisplayOrderId}
                                mutateDeleteOrdersOperations={mutateDeleteOrdersOperations}
                                openTransferDialog={openTransferDialog}
                                isLoading={isLoading}
                                deliveryEntitiesOnPosition={deliveryEntities.filter((it) => it.id === deliveryId)}
                                truckEntities={truckEntities}
                                deliveryEntities={deliveryEntities}
                                driverEntities={driverEntities}
                                editPlaces={[{
                                  deliveryId,
                                  cycleIndex,
                                  places: [editPlaces.find((it) => it.id === activeId)]
                                }]}
                                planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
                                addedOrderIds={addedOrderIds}
                                parentCheckedOperationAdded={checkedOperationAdded}
                                mutateRestoreSplittedOrder={mutateRestoreSplittedOrder}
                              />
                            </div>
                          )}
                        </DragOverlay>
                      </SortableContext>
                    </DndContext>
                  </Stack>
                </Paper>
              </ListItem>
            </List>
          </Collapse>
        )
      }
    </Stack>
  );
});

export default PlanningsCyclePresenter;

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Avatar, Box, Checkbox, Link, Stack, Tooltip, Typography } from '@mui/material';
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import numberDecorator from 'src/decorators/number.decorator';
import { EditPlaceEntity } from 'src/entities/EditPlace.entity';
import { PastOperationAndDriverIdEntity } from 'src/entities/PastOperationAndDriverId.entity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsOperationDeliveryByDeliveryIdEntity } from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { TransferRequestEntity } from 'src/entities/transferRequestEntity';
import { PlanningsOperationCycle, PlanningsOperationPlace } from 'src/models/PlanningsOperationGroup.model';

import PlanningMapOperationDetailDialog from './PlanningMapOperationDetailDialog.presenter';

type Props = {
  idx: number;
  cycle: PlanningsOperationCycle;
  place: PlanningsOperationPlace;
  pastOperations: PastOperationAndDriverIdEntity[];
  onChangePlaceSelection: (place: PlanningsOperationPlace) => void;
  driverEntitiesOnPosition: PlanningsDriverEntity[];
  startOn: string;
  endOn: string;
  updateDisplayOrderId: (orderId: number) => void;
  mutateDeleteOrdersOperations: (requestOrderIds: number[]) => void;
  openTransferDialog: (entity: TransferRequestEntity) => void;
  isLoading: boolean;
  deliveryEntitiesOnPosition: PlanningsDeliveryEntity[];
  truckEntities: PlanningsTruckEntity[];
  deliveryEntities: PlanningsDeliveryEntity[];
  driverEntities: PlanningsDriverEntity[];
  editPlaces: EditPlaceEntity[];
  planningsOperationDeliveryByDeliveryIdEntity: PlanningsOperationDeliveryByDeliveryIdEntity;
  addedOrderIds: number[];
  parentCheckedOperationAdded: boolean;
  mutateRestoreSplittedOrder: (orderId: number) => void;
}

const PlanningsPlacePresenter: FC<Props> = memo(({
  idx,
  cycle,
  place,
  pastOperations,
  onChangePlaceSelection,
  driverEntitiesOnPosition,
  startOn,
  endOn,
  updateDisplayOrderId,
  mutateDeleteOrdersOperations,
  openTransferDialog,
  isLoading,
  deliveryEntitiesOnPosition,
  truckEntities,
  deliveryEntities,
  driverEntities,
  editPlaces,
  planningsOperationDeliveryByDeliveryIdEntity,
  addedOrderIds,
  parentCheckedOperationAdded,
  mutateRestoreSplittedOrder,
}) => {
  const [firstVisitPlace, setFirstVisitPlace] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [checkedOperationAdded, setCheckedOperationAdded] = useState<boolean>(false);

  useEffect(() => {
    if (!parentCheckedOperationAdded) {
      setCheckedOperationAdded(false);
      return;
    }
    const currentOrderIds = place.allOperations().map((it) => it.orderId);
    setCheckedOperationAdded(addedOrderIds.some((it) => currentOrderIds.includes(it)));
  }, [addedOrderIds, parentCheckedOperationAdded, place]);

  const bgColor = useMemo(() => {
    if (checkedOperationAdded) {
      return '#D6E3F6';
    }
    return '';
  }, [checkedOperationAdded]);

  useEffect(() => {
    if (!pastOperations || pastOperations.length === 0 || !place || !place.isAction('降')) {
      return;
    }

    setFirstVisitPlace(
      pastOperations.filter((it) => it.unloadingLatitude === place.latitude && it.unloadingLongitude === place.longitude).length === 0
    );
  }, [pastOperations, place]);

  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: place.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
  };

  const onClickPlaceName = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '地点リンク' });
    setDialogIsOpen(true);
  }, []);

  const statusColor = useMemo(() => {
    switch (place.status?.statusJa) {
      case '案件開始':
        return '#5498d4';
      case '待機開始':
        return '#dfd661';
      case '待機完了':
        return '#97e024';
      case '作業開始':
        return '#1d88eb';
      case '作業完了':
        return '#999';
      default:
        return '';
    }
  }, [place.status?.statusJa]);

  const onClickCheckbox = useCallback((event: React.ChangeEvent, checked: boolean) => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: `地点チェック ${checked ? 'ON' : 'OFF'}` });

    onChangePlaceSelection(place);
  }, [onChangePlaceSelection, place]);

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={0.5}
      padding={1}
      sx={{
        '&:hover .dragIcon': {
          display: 'flex',
        },
        backgroundColor: bgColor,
      }}
      {...attributes}
      ref={setNodeRef}
      style={style}
    >
      <Tooltip title="ドラッグして並べ替えます">
        <Stack direction="row" {...listeners}>
          <DragIndicatorIcon
            fontSize="small"
            sx={{
              position: 'absolute',
              left: 0,
              display: 'none',
              cursor: 'grab',
              color: 'grey'
            }}
            className="dragIcon"
          />
          {place.status && place.status.statusJa !== '未配送' ? (
            <Tooltip title={place.status.statusJa}>
              <Typography
                variant="body2"
                fontSize="0.8em"
                sx={{
                  cursor: 'grab',
                  color: '#fff',
                  backgroundColor: statusColor
                }}
              >
                {datetimeDecorator.toHourMinutes(new Date(place.status.reportAt))}
              </Typography>
            </Tooltip>
          ) : (
            <Typography
              variant="body2"
              fontSize="0.8em"
              sx={{
                cursor: 'grab',
              }}
            >
              {datetimeDecorator.toHourMinutes(new Date(place.arrivalAt))}
            </Typography>
          )}
        </Stack>
      </Tooltip>
      <Stack
        direction="column"
        sx={{
          alignSelf: 'stretch',
        }}
      >
        <Avatar
          sx={{
            height: '20px',
            width: '20px',
            backgroundColor: 'primary.main',
          }}
        >
          {place.seriesIndex}
        </Avatar>
        {(idx < cycle.places.length - 1) && (
          <Box
            sx={{
              width: '2px',
              backgroundColor: 'primary.main',
              flexGrow: 1,
              marginTop: '4px', // アイコンとの間に少しスペースを追加
              marginLeft: '8px',
              alignSelf: 'stretch',
            }}
          />
        )}
      </Stack>
      <Stack direction="row" justifyContent="center">
        {place.isAction('積') && (
          <ArrowCircleUpRoundedIcon
            color="success"
            fontSize="small"
            sx={{ opacity: place.isAction('積') ? 1 : 0 }}
          />
        )}
        {place.isAction('降') && (
          <ArrowCircleDownRoundedIcon
            color="error"
            fontSize="small"
            sx={{ opacity: place.isAction('降') ? 1 : 0 }}
          />
        )}
      </Stack>
      <Stack>
        <Stack direction="row" sx={{ display: 'flex', alignItems: 'flex-start' }} spacing={1} justifyContent="space-between">
          <Stack direction="row" alignItems="flex-start">
            <Checkbox
              size="small"
              sx={{
                p: 0,
                m: 0,
              }}
              onChange={onClickCheckbox}
            />
            <Link variant="body2" component="button" onClick={onClickPlaceName} sx={{ textAlign: 'left' }}>
              {place.name}
            </Link>
          </Stack>
          <Stack direction="row" alignItems="flex-start">
            {place.includesSplitOrder() && (
              <Tooltip title="自動分割された案件を含みます">
                <CallSplitIcon color="error" fontSize="small" sx={{ p: 0, m: 0, }} />
              </Tooltip>
            )}
            {place.isOutsideSpecifiedTime() && (
              <Tooltip title="指定時間が超過しています。">
                <WarningAmberRoundedIcon color="error" fontSize="small" sx={{ p: 0, m: 0, }} />
              </Tooltip>
            )}
            {firstVisitPlace && (
              <Tooltip title="初めて訪問する納品先です">
                <NotificationImportantIcon color="success" fontSize="small" sx={{ p: 0, m: 0 }} />
              </Tooltip>
            )}
          </Stack>
        </Stack>
        <Typography variant="body2" fontSize="0.8em">
          {place.address}
        </Typography>
        <Typography variant="body2" fontSize="0.8em">
          {
            [
              ['待機:', place.waitingSeconds > 0 ? numberDecorator.convertSecondsToMinutes(place.waitingSeconds) : '0分'].join(''),
              ['作業:', place.workingSeconds > 0 ? numberDecorator.convertSecondsToMinutes(place.workingSeconds) : '0分'].join(''),
              [
                '積載:',
                [
                  numberDecorator.convertGramToTons(place.operationWeightG, 2),
                  place.operationVolumeMm3 > 0 ? numberDecorator.convertMm3ToM3(place.operationVolumeMm3) : null,
                ].filter((it) => it).join(', ')
              ].join(''),
            ].join(' ')
          }
        </Typography>
      </Stack>
      {dialogIsOpen && (
        <PlanningMapOperationDetailDialog
          position={place.position}
          driverEntitiesOnPosition={driverEntitiesOnPosition}
          startOn={startOn}
          endOn={endOn}
          updateDisplayOrderId={updateDisplayOrderId}
          mutateDeleteOrdersOperations={mutateDeleteOrdersOperations}
          openTransferDialog={openTransferDialog}
          isLoading={isLoading}
          deliveryEntitiesOnPosition={deliveryEntitiesOnPosition}
          truckEntities={truckEntities}
          deliveryEntities={deliveryEntities}
          driverEntities={driverEntities}
          dialogIsOpen={dialogIsOpen}
          setDialogIsOpen={setDialogIsOpen}
          editPlaces={editPlaces}
          planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
          mutateRestoreSplittedOrder={mutateRestoreSplittedOrder}
        />
      )}
    </Stack>
  );
});

export default PlanningsPlacePresenter;
